import { defineStyleConfig } from '@chakra-ui/react'

export const textareaTheme = defineStyleConfig({
  baseStyle: {
    padding: '8px 14px !important',
    fontSize: 'sm',
    _placeholder: {
      fontSize: 'sm',
      paddingX: 0,
    },
    _focus: {
      boxShadow: 'none !important',
      border: '1px solid !important',
      borderColor: 'gray.800',
    },
  },
})

export default textareaTheme
