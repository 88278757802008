import { defineStyleConfig } from '@chakra-ui/react'

import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const helpers = createMultiStyleConfigHelpers(['item', 'button'])

export const menuTheme = helpers.defineMultiStyleConfig({
  baseStyle: {
    item: {
      fontSize: '12px !important',
    },
  },
})

export default menuTheme
