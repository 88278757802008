import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const helpers = createMultiStyleConfigHelpers(['addon', 'field', 'element'])

export const inputTheme = helpers.defineMultiStyleConfig({
  baseStyle: {
    addon: {
      fontSize: 'sm',
    },
    field: {
      boxShadow: 'none',
      fontSize: 'sm',
      _placeholder: {
        fontSize: 'sm',
        position: 'relative',
      },
      _focus: {
        border: '1px solid !important',
        boxShadow: 'none !important',
        outline: '0px',
      },
    },
    element: {
      fontSize: 'sm',
      _focus: {
        border: '1px solid !important',
        boxShadow: 'none !important',
        outline: '0px',
      },
    },
  },
})

export default inputTheme
