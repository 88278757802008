import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const helpers = createMultiStyleConfigHelpers([
  'container',
  'header',
  'body',
  'footer',
])

export const cardTheme = helpers.defineMultiStyleConfig({
  baseStyle: {
    container: {
      padding: 3,
    },
  },
})

export default cardTheme
