import { Button, Center, Text, VStack } from '@chakra-ui/react'
import { useRouter } from 'next/router'

export default function Fallback() {
  const router = useRouter()
  return (
    <Center h='full'>
      <VStack>
        <Text>Something went wrong</Text>
        <Button
          variant='primaryCTA'
          onClick={() => {
            router.reload()
          }}
        >
          Go back
        </Button>
      </VStack>
    </Center>
  )
}
