import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const helpers = createMultiStyleConfigHelpers([
  'control',
  'icon',
  'container',
  'label',
])

export const checkboxTheme = helpers.defineMultiStyleConfig({
  baseStyle: {
    control: {
      borderRadius: 'sm',
      padding: 1,
      border: '1px solid',
      height: 4,
      width: 4,
    },
    icon: {
      width: '1px !important',
      height: '7px !important',
    },
  },
})

export default checkboxTheme
