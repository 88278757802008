import React, { ReactNode, useReducer } from 'react'
import { Store, initialState } from './Store'
import { get } from 'lodash'

export type Action = {
  type: string
  payload: unknown
}

export type ModalAction = Action & {
  payload: {
    key: string
    open: boolean
    data: unknown
  }
}

export const DataProvider = ({
  children,
  Provider,
}: {
  children: ReactNode | Array<ReactNode>
  Provider: any
}) => {
  const [state, dispatch] = useReducer((prevState: Store, action: Action) => {
    switch (action.type) {
      case 'SET_USER':
        return Object.assign({}, prevState, {
          user: action.payload,
        })
      case 'SET_SOURCE':
        return Object.assign({}, prevState, {
          initialUrl: action.payload,
        })

      case 'TOGGLE_SIDEBAR':
        return Object.assign({}, prevState, {
          sidebar: {
            collapsed: action.payload,
          },
        })
      case 'TOGGLE_MODAL':
        if (
          [
            'replaceField',
            'editField',
            'editValidation',
            'replacePicklist',
          ].includes((action as ModalAction).payload.key) &&
          !(action as ModalAction).payload.data
        ) {
          return prevState
        }

        return Object.assign({}, prevState, {
          modal: {
            key: (action as ModalAction).payload.key,
            data: (action as ModalAction).payload.data,
          },
        })
      case 'SET_LOGIN_ERRORS':
        return Object.assign({}, prevState, {
          login: {
            errors: action.payload,
          },
        })
      case 'SET_COMMAND_WRAPPER_REF':
        return Object.assign({}, prevState, {
          commandMenu: {
            rootRef: action.payload,
          },
        })
      case 'SET_DIALOGUE_WRAPPER_REF':
        return Object.assign({}, prevState, {
          dialogue: {
            rootRef: action.payload,
          },
        })
      case 'CLEAR_SELECTED_REPORT_FILE_SYSTEM':
        const selectedKey = Object.keys(prevState.reportFileStore).find(e =>
          get(prevState.reportFileStore, `${e}.selected`, false),
        )

        return Object.assign({}, prevState, {
          reportFileStore: {
            ...prevState.reportFileStore,
            [selectedKey as string]: {
              ...(prevState.reportFileStore[selectedKey as string] || {}),
              selected: false,
            },
          },
        })
      case 'SET_REPORT_FILE_SYSTEM':
        let updatedPrevState = prevState.reportFileStore || {}

        if ((action.payload as any).selected !== undefined) {
          const selectedKey = Object.keys(updatedPrevState).find(e =>
            get(updatedPrevState, `${e}.selected`, false),
          )

          if (selectedKey !== undefined) {
            updatedPrevState = Object.assign({}, updatedPrevState, {
              [selectedKey as string]: {
                ...(prevState.reportFileStore[selectedKey as string] || {}),
                selected: false,
              },
              [(action.payload as any).id as string]: {
                ...(prevState.reportFileStore[
                  (action.payload as any).id as string
                ] || {}),
                ...(action.payload || {}),
                selected: (action.payload as any).selected,
              },
            })
          } else {
            if (
              (
                (prevState.reportFileStore[(action.payload as any).id] ||
                  {}) as any
              ).selected === false
            ) {
              updatedPrevState = Object.assign({}, updatedPrevState, {
                [(action.payload as any).id]: {
                  ...(prevState.reportFileStore[
                    (action.payload as any).id as string
                  ] || {}),
                  selected: true,
                  ...(action.payload || {}),
                },
              })
            } else if (
              (
                (prevState.reportFileStore[(action.payload as any).id] ||
                  {}) as any
              ).selected === undefined
            ) {
              updatedPrevState = Object.assign({}, updatedPrevState, {
                [(action.payload as any).id]: {
                  ...(prevState.reportFileStore[
                    (action.payload as any).id as string
                  ] || {}),
                  selected: false,
                  ...(action.payload || {}),
                },
              })
            } else if (
              (
                (prevState.reportFileStore[(action.payload as any).id] ||
                  {}) as any
              ).selected === true
            ) {
              updatedPrevState = Object.assign({}, updatedPrevState, {
                [(action.payload as any).id]: {
                  ...(action.payload || {}),
                  ...(prevState.reportFileStore[
                    (action.payload as any).id as string
                  ] || {}),
                  selected: false,
                },
              })
            }
          }

          return Object.assign({}, prevState, {
            reportFileStore: updatedPrevState,
          })
        } else {
          return Object.assign({}, prevState, {
            reportFileStore: {
              ...updatedPrevState,
              [(action.payload as any).id]: action.payload,
            },
          })
        }
      case 'SET_REPORT_FOLDER_QUERIES':
        return Object.assign({}, prevState, {
          reportFolders: prevState.reportFolders.map(e => {
            if ((e as any).id !== (action.payload as any).id) {
              return e
            } else {
              return {
                ...e,
                items: (action.payload as any).report_queries,
              }
            }
          }),
        })
      case 'CLEAR_REPORT_QUERIES':
        return Object.assign({}, prevState, {
          reportFolders: prevState.reportFolders.map(e => {
            if ((e as any).id !== (action.payload as any).id) {
              return e
            } else {
              return {
                ...e,
                items: [],
              }
            }
          }),
        })
      case 'SET_REPORT_QUERIES':
        return Object.assign({}, prevState, {
          reportQueries: action.payload,
        })
      case 'SET_CURRENT_REPORT_FOLDER':
        return Object.assign({}, prevState, {
          currentFolder: action.payload,
        })
      case 'SET_REPORT_QUERIES_LOADING':
        return Object.assign({}, prevState, {
          reportQueriesLoading: action.payload,
        })
      case 'SET_REPORT_FOLDERS_LOADING':
        return Object.assign({}, prevState, {
          reportFoldersLoading: action.payload,
        })
      case 'SET_REPORT_FOLDERS':
        return Object.assign({}, prevState, {
          reportFolders: action.payload,
        })
      case 'SET_FLOW_NODE_DATA':
        return Object.assign({}, prevState, {
          flowsNodeData: {
            ...prevState.flowsNodeData,
            [(action.payload as any).id]: (action.payload as any).data,
          },
        })
      case 'SET_TABLE_EDITOR_OPEN':
        return Object.assign({}, prevState, {
          tableEditorOpen: !(
            prevState.tableEditorOpen ||
            action.payload ||
            false
          ),
        })
      case 'SET_TABLE_EDIT_PROMPT_OPEN':
        return Object.assign({}, prevState, {
          tableEditPromptOpen: !(
            prevState.tableEditPromptOpen ||
            action.payload ||
            false
          ),
        })
      case 'SET_TABLE_EDITOR_LOADING':
        return Object.assign({}, prevState, {
          tableEditorLoading: !(prevState.tableEditorLoading || false),
        })

      case 'SET_RECENT_REPORT_QUERY_PARAMS':
        return Object.assign({}, prevState, {
          recentReportQueryParams: action.payload,
        })
      case 'SET_RECENT_CUSTOM_FIELDS':
        return Object.assign({}, prevState, {
          customFields: action.payload,
        })
      case 'SET_REPORT_DEPLOY_PARAMS':
        return Object.assign({}, prevState, {
          reportDeployParams: action.payload,
        })
      case 'SET_REFETCH_VALIDATION_RULES':
        return Object.assign({}, prevState, {
          refetchValidationRules: action.payload,
        })
      case 'SET_REFETCH_FIELDS':
        return Object.assign({}, prevState, {
          refetchFields: action.payload,
        })

      case 'SET_REPORT_AGGREGATIONS': {
        return Object.assign({}, prevState, {
          reportAggregations: action.payload,
        })
      }

      default:
        return prevState
    }
  }, initialState)

  return <Provider value={{ state, dispatch }}>{children}</Provider>
}
