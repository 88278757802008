import { defineStyleConfig } from '@chakra-ui/react'

export const containerTheme = defineStyleConfig({
  variants: {
    userDetail: {
      padding: 2,
      borderRadius: 'md',
      width: '100%',
      _hover: {
        background: 'gray.200',
      },
    },
    userDetailCollapsed: {
      background: 'none',
      borderRadius: '0',
      border: '0px',
      padding: '0 !important',
      margin: '0 !important',
      borderColor: 'none',
      width: 'fit-content',
      minWidth: '0px',
      _hover: {
        background: 'none',
      },
    },
    applicationContainer: {
      margin: 0,
      minWidth: '100%',
      width: '100%',
      padding: 0,
      height: '100%',
    },
    emptyCell: {
      background: 'gray.50',
      minHeight: '100%',
      height: '32px',
      maxWidth: '28px',
      borderRight: '1px solid',
      borderRightColor: 'gray.200',
      minWidth: '1px',
      margin: 0,
      padding: 0,
      textTransform: 'normal',
      letterSpacing: 'xs',
      fontSize: 'xs',
      fontWeight: 500,
      color: 'gray.500',
    },
    sidebarIcon: {
      minWidth: '20px !important',
      minHeight: '20px !important',
      width: '20px',
      height: '20px',
      maxWidth: '20px',
      maxHeight: '20px',
    },
    blank: {
      padding: 0,
      margin: 0,
    },
    accountLink: {
      padding: 0,
      margin: 0,
      color: 'blue.600',
      fontWeight: 'semibold',
    },
  },
})

export default containerTheme
