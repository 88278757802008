import { defineStyleConfig } from '@chakra-ui/react'

export const buttonTheme = defineStyleConfig({
  baseStyle: {
    fontWeight: 500,
    fontSize: 'sm',
    borderRadius: 4,
  },
  variants: {
    formSubmit: {
      background: 'gray.900',
      color: 'gray.100',
      _disabled: {
        background: 'gray.100',
        color: 'gray.500',
        _hover: {
          background: 'gray.100 !important',
        },
      },
    },
    formCancel: {
      background: 'gray.100',
      color: 'gray.500',
      _hover: {
        background: 'gray.200',
      },
    },
    userSettings: {
      margin: '0px 8px',
      padding: '8px',
      borderRadius: 'md',
      _hover: {
        background: 'gray.200',
      },
    },
    userSettingsCollapsed: {
      maxWidth: '30px',
      maxHeight: '30px',
      minWidth: '0px',
      minHeight: '0px',
      width: '30px',
      height: '30px',
      margin: '0px 4px',
      _hover: {
        background: 'gray.200',
      },
    },
    save: {
      background: 'gray.100',
      _hover: {
        background: 'gray.200',
      },
      color: 'gray.500',
      fontSize: 'xs',
      padding: 2,
      paddingX: '3 !important',
      margin: 0,
      lineHeight: 'sm',
      borderRadius: '4px',
      height: 'fit-content',
    },
    download: {
      background: 'blue.500',
      _hover: {
        background: 'blue.600',
      },
      borderRadius: '4px',
      color: 'white',
      fontSize: 'xs',
      padding: 2,
      paddingX: '3 !important',
      margin: 0,
      lineHeight: 'sm',
      height: 'fit-content',
    },
    primaryCTA: {
      size: 'sm',
      bg: 'black',
      color: 'white',
      _hover: { bg: '#474747' },
    },
  },
  sizes: {
    sm: {
      height: '32px',
    },
  },
})

export default buttonTheme
