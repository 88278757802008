import { defineStyleConfig } from '@chakra-ui/react'

export const textTheme = defineStyleConfig({
  baseStyle: {
    fontWeight: 400,
  },
  variants: {
    h1: {
      fontSize: 'lg',
      fontWeight: 500,
      color: 'gray.800',
    },
    h3: {
      fontSize: 'sm',
      fontWeight: 500,
      color: 'gray.800',
    },
    checkboxLabel: {
      fontSize: '14px',
      fontWeight: 400,
      color: 'gray.500',
      position: 'relative',
    },
    primarySubtitle: {
      fontSize: 'sm',
      fontWeight: 400,
      color: 'gray.400',
    },
    description: {
      fontSize: 'sm',
      fontWeight: 400,
      color: 'gray.600',
    },
    caption: {
      fontSize: 'sm',
      fontWeight: 500,
      color: 'black',
    },
    pageHeader: {
      fontSize: '2xl',
      fontWeight: 600,
      color: 'gray.800',
      lineHeight: '1.5rem',
    },
  },
})

export default textTheme
