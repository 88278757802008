import { Ref, RefObject, createContext } from 'react'
import { Action } from './Provider'
import { ModalKey } from './RootModal'
import { Folder, ReportQuery } from '../types'

export type UserState = {
  id?: string
  name?: string
  salesforce_user_id?: string
  salesforce_org_member_id?: string
  salesforce_instance_url?: string
  account?: AccountState
  email?: string
  is_manager?: boolean
  is_super_admin?: boolean
  plan?: 'enterprise' | 'pipeline'
}

export type AccountState = {
  description: string | undefined
  tags: Array<any>
  id?: any
  forecast_period_type?: string
}

export type SidebarState = {
  open?: boolean
}

export type CommandMenuState = {
  open?: boolean
  rootRef?: {
    ref: RefObject<Ref<HTMLDivElement>>
  }
}

export type ModalState = {
  key?: ModalKey
}

export type LoginState = {
  hasErrors?: boolean
}

export type ReportFileState = Record<string, unknown>

export type Store = {
  user?: UserState
  sidebar?: SidebarState
  commandMenu?: CommandMenuState
  login?: LoginState
  reportFileStore: ReportFileState
  reportQueries: Array<ReportQuery>
  reportFolders: Array<Folder>
  flowsNodeData: Record<string, any>
  tableEditorOpen: boolean
  tableEditPromptOpen: boolean
  tableEditorLoading: boolean
  recentReportQueryParams: Record<string, any>
  customFields: Array<Record<string, string>>
  reportDeployParams: Record<string, any>
  refetchValidationRules: boolean
  refetchFields: boolean
  [key: string]: any
}

export type ActionDispatcher = {
  state: Store
  dispatch: (action: Action) => void
}

export const initialState: Store = {
  user: {},
  sidebar: {},
  login: {},
  reportFileStore: {},
  reportQueries: [],
  reportFolders: [],
  flowsNodeData: {},
  tableEditorOpen: false,
  tableEditPromptOpen: false,
  refetchValidationRules: false,
  refetchFields: false,
  tableEditorLoading: false,
  recentReportQueryParams: {},
  customFields: [],
  reportDeployParams: {},
}

export const StateStore = createContext<ActionDispatcher>(
  initialState as unknown as ActionDispatcher,
)
