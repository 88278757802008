import { defineStyleConfig } from '@chakra-ui/react'

export const badgeTheme = defineStyleConfig({
  variants: {
    objectBadge: {
      background: 'gray.200',
      color: 'gray.700',
      border: '1px solid',
      borderColor: 'gray.200',
      fontWeight: 400,
      borderRadius: '3',
      textTransform: 'humanize',
      paddingX: 3,
      paddingY: 1,
      fontSize: '10px',
    },
  },
})

export default badgeTheme
