import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const helpers = createMultiStyleConfigHelpers([
  'thead',
  'tbody',
  'tfoot',
  'tr',
  'td',
  'th',
  'caption',
  'container',
])

export const tableTheme = helpers.defineMultiStyleConfig({
  baseStyle: {},
  variants: {
    dataGrid: {
      container: {
        display: 'flex',
        flexDirection: 'column',
      },
      th: {
        padding: 2,
        background: 'gray.50',
        borderRight: '1px solid',
        borderRightColor: 'gray.200',
        textTransform: 'normal',
        letterSpacing: 'xs',
        fontSize: 'xs',
        fontWeight: 500,
        color: 'gray.500',
        flexGrow: 1,
        width: '100%',
        minWidth: '100%',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        display: 'flex',
      },
      thead: {
        border: '1px solid',
        borderColor: 'gray.200',
        flexDirection: 'row',
        display: 'flex',
        flexGrow: 1,
        width: '100%',
      },
      tr: {
        borderBottom: '1px solid',
        borderRight: '1px solid',
        borderLeft: '1px solid',
        borderBottomColor: 'gray.200',
        borderRightColor: 'gray.200',
        borderLeftColor: 'gray.200',
        display: 'flex',
        width: '100%',
        flexDirection: 'row',
      },

      td: {
        padding: 2,
        fontSize: 'xs',
        borderRight: '1px solid',
        borderRightColor: 'gray.200',
        flexGrow: 1,
        width: '100%',
      },
      tbody: {},
      tfoot: {},
    },
  },
})

export default tableTheme
