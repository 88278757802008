import { ChakraProvider, extendTheme } from '@chakra-ui/react'
import React from 'react'
import { ContainerProps } from '../types'
import badgeTheme from './Themes/Badge'
import buttonTheme from './Themes/Button'
import headingTheme from './Themes/Heading'
import modalTheme from './Themes/Modal'
import textTheme from './Themes/Text'
import checkboxTheme from './Themes/Checkbox'
import menuTheme from './Themes/Menu'
import inputTheme from './Themes/Input'
import containerTheme from './Themes/Container'
import textareaTheme from './Themes/Textarea'
import cardTheme from './Themes/Card'
import tableTheme from './Themes/Table'

type Props = {} & ContainerProps

export const ChakraTheme = extendTheme({
  components: {
    Button: buttonTheme,
    Text: textTheme,
    Heading: headingTheme,
    Badge: badgeTheme,
    Modal: modalTheme,
    Checkbox: checkboxTheme,
    Menu: menuTheme,
    Input: inputTheme,
    Container: containerTheme,
    Textarea: textareaTheme,
    Card: cardTheme,
    Table: tableTheme,
  },
})

const ChakraSystemProvider = (props: Props) => {
  return (
    <ChakraProvider
      {...{
        theme: ChakraTheme,
      }}
    >
      {props.children}
    </ChakraProvider>
  )
}

export default ChakraSystemProvider
