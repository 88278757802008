import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const helpers = createMultiStyleConfigHelpers([
  'header',
  'footer',
  'closeButton',
  'body',
  'overlay',
  'content',
  'dialog',
])

export const modalTheme = helpers.defineMultiStyleConfig({
  baseStyle: {
    dialog: {
      maxWidth: '0px',
    },
    header: {
      fontWeight: 500,
      color: 'gray.700',
      fontSize: 'md',
      paddingX: 4,
      borderBottom: '1px solid',
      borderColor: 'gray.100',
    },
    overlay: {
      background: 'rgba(0, 0, 0, 0.2)',
    },
    body: {
      padding: 4,
    },
    closeButton: {
      width: 6,
      height: 6,
      top: '15px',
      right: '18px',
    },
    footer: {
      borderTop: '1px solid',
      borderColor: 'gray.100',
      display: 'flex',
      gap: 2,
      paddingX: 4,
      paddingY: 4,
    },
  },
})

export default modalTheme
